<script>
import { sync, get } from 'vuex-pathify'

export default {
	data() {
		return {
			mobileDrawerLinks: this.$srv('mobileMenu.data'),
			categories: this.$srv('mobileMenu.categories'),
			selectedItem: null,
			brands: null,
			isActive: false,
			brandSearch: '',
		}
	},
	created() {
		this.mobileDrawerLinks
			.filter((l) => l.type === 'categories')
			.forEach((l) => {
				l.categories = this.categories
			})
	},
	computed: {
		menuMobileDrawer: sync('shop/menuMobileDrawer'),
		user: get('shop/user'),
		brandsDialog: sync('shop/brandsDialog'),
		height() {
			if (this.$ssr) return
			return window.innerHeight - 64 - 58
		},
		filteredBrands() {
			let brands = this.brands
			if (this.brandSearch) {
				brands = brands.filter((x) => x.name.toLowerCase().includes(this.brandSearch.toLowerCase()))
			}
			return brands
		},
	},
	methods: {
		pop() {
			if (this.selectedItem.length === 1) this.selectedItem = null
			else this.selectedItem.pop()
		},
		linkIsAbsolute(link) {
			const absoluteUrlRegex = /^([a-z]+:\/\/|(mailto|tel):)/i
			return typeof link === 'string' && link.match(absoluteUrlRegex)
		},
		getVBind(item) {
			if (this.linkIsAbsolute(item.link)) {
				return {
					href: item.link,
					target: '_blank',
				}
			} else {
				return {
					to: item.link,
				}
			}
		},
	},
	watch: {
		menuMobileDrawer() {
			this.selectedItem = null
		},
		selectedItem(val) {
			if (val?.type !== 'allBrands' || this.brands?.length) return
			this.$shopApi.get({
				url: '/catalog/brands',
				query: { buyables: true },
				onSuccess: ({ data }) => {
					this.brands = data.brands
				},
			})
		},
	},
}
</script>

<template>
	<div v-if="mobileDrawerLinks">
		<v-slide-x-transition mode="out-in">
			<v-list key="list" v-if="!selectedItem" class="pt-15" color="base">
				<div v-for="(item, i) of mobileDrawerLinks" :key="i">
					<div v-if="item.type === 'allBrands'" @click="brandsDialog = true">
						<v-list-item>
							<v-list-item-icon v-if="item.iconType && item.iconType !== 'none'">
								<ListItemIcon :item="item" />
							</v-list-item-icon>

							<v-list-item-title class="base">
								{{ item.name }}
								<v-icon style="position: absolute; right: 15px; top: 0; bottom: 0">
									mdi-chevron-right
								</v-icon>
							</v-list-item-title>
						</v-list-item>
					</div>

					<div v-if="item.type === 'categories'" @click="selectedItem = [item]">
						<div class="base font-1">
							<v-list-item>
								<v-list-item-icon v-if="item.iconType && item.iconType !== 'none'">
									<ListItemIcon :item="item" />
								</v-list-item-icon>
								<v-list-item-title class="base">
									{{ item.name }}
									<v-icon
										v-if="categories.length"
										style="position: absolute; right: 15px; top: 0; bottom: 0"
									>
										mdi-chevron-right
									</v-icon>
								</v-list-item-title>
							</v-list-item>
						</div>
					</div>

					<div v-if="item.type === 'account'">
						<div class="base font-1" v-if="user">
							<v-list-item :to="{ name: 'user.favorites' }">
								<v-list-item-icon>
									<v-icon :color="$vars.basetext">mdi-heart</v-icon>
								</v-list-item-icon>
								<v-list-item-title> Favoritos </v-list-item-title>
							</v-list-item>

							<v-list-item :to="{ name: 'user.account' }">
								<v-list-item-icon>
									<v-icon>mdi-account</v-icon>
								</v-list-item-icon>
								<v-list-item-title>
									{{ user.firstname }}
								</v-list-item-title>
							</v-list-item>
						</div>

						<v-list-item v-else :color="$vars.basetext" class="base">
							<v-list-item-icon>
								<v-icon :color="$vars.basetext">mdi-account</v-icon>
							</v-list-item-icon>

							<v-list-item-title @click="$emit('show-login-drawer')" class="font-1">
								Mi cuenta</v-list-item-title
							>
						</v-list-item>
					</div>

					<div v-if="item.type === 'custom'">
						<v-list-group v-if="item.children" append-icon="mdi-chevron-down" class="base">
							<template v-slot:prependIcon>
								<ListItemIcon :item="item" v-if="item.iconType && item.iconType !== 'none'" />
							</template>
							<template v-slot:activator>
								<v-list-item-title :color="$vars.basetext" class="base font-1">
									{{ item.name }}
								</v-list-item-title>
							</template>
							<SafeLink
								is="v-list-item"
								class="px-5 base"
								v-for="(subitem, index) of item.children"
								:key="index"
								v-bind="getVBind(subitem)"
							>
								<v-list-item-title class="pl-3"> - {{ subitem.name }} </v-list-item-title>
							</SafeLink>
						</v-list-group>

						<SafeLink is="v-list-item" v-else v-bind="getVBind(item)" class="base w100">
							<v-list-item-icon v-if="item.iconType && item.iconType !== 'none'">
								<ListItemIcon :item="item" />
							</v-list-item-icon>

							<v-list-item-title :color="$vars.basetext" class="base font-1">{{
								item.name
							}}</v-list-item-title>
						</SafeLink>
					</div>
				</div>
			</v-list>

			<v-list key="sublist" v-if="selectedItem" class="pt-15">
				<div v-if="Array.isArray(selectedItem)">
					<v-slide-x-transition mode="out-in">
						<MobileMenuDrawer-Categories
							:selectedItem="selectedItem"
							key="odd"
							v-if="selectedItem.length % 2"
							@push="selectedItem.push($event)"
							@pop="pop"
						/>
						<MobileMenuDrawer-Categories
							:selectedItem="selectedItem"
							key="even"
							v-else
							@push="selectedItem.push($event)"
							@pop="pop"
						/>
					</v-slide-x-transition>
				</div>

				<div v-else>
					<div class="sublist-header text-center py-4">
						<div @click="selectedItem = null" v-ripple>
							<v-icon style="position: absolute; left: 10px; height: auto">mdi-chevron-left</v-icon>
							{{ selectedItem.name }}
						</div>
					</div>
					<div>
						<div class="px-2">
							<TextField clearable v-model="brandSearch" label="Filtrar..." />
						</div>
						<div v-if="brands && brands.length">
							<v-virtual-scroll :items="filteredBrands" :height="height" item-height="49">
								<template #default="{ item }">
									<SafeLink
										is="v-list-item"
										class="base w100"
										:to="$shop.getShopRoute({ brand: item.urlName })"
									>
										<v-list-item-title :color="$vars.basetext" class="base font-1">{{
											item.name
										}}</v-list-item-title>
									</SafeLink>
								</template>
							</v-virtual-scroll>
						</div>
						<div v-else>
							<v-skeleton-loader
								class="mb-6"
								boilerplate
								elevation="2"
								type="list-item, list-item, list-item"
							></v-skeleton-loader>
						</div>
					</div>
				</div>
			</v-list>
		</v-slide-x-transition>
	</div>
</template>

<style scoped lang="scss">
.sublist-header {
	min-height: 25px;
	position: relative;
}

.sublist-header,
::v-deep .v-list-group__header,
::v-deep .v-list-item {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
