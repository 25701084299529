import { render, staticRenderFns } from "./comp_MobileMenuDrawer-CustomizableLinks.vue?vue&type=template&id=59393213&scoped=true"
import script from "./comp_MobileMenuDrawer-CustomizableLinks.vue?vue&type=script&lang=js"
export * from "./comp_MobileMenuDrawer-CustomizableLinks.vue?vue&type=script&lang=js"
import style0 from "./comp_MobileMenuDrawer-CustomizableLinks.vue?vue&type=style&index=0&id=59393213&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59393213",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VIcon,VList,VListGroup,VListItem,VListItemIcon,VListItemTitle,VSkeletonLoader,VSlideXTransition,VVirtualScroll})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
